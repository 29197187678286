<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <div class="drawer-bar auto">
        <div class="fl">
          <span class="font16"><b>导入外部数据</b></span>
          <!-- <a href="javascript:;" @click="goLog" class="go-log-btn">查看导入导出记录</a> -->
        </div>
      </div>
      <div class="department-content auto">
        <div class="batch-content auto">
          <a-spin :spinning="loading">
            <div class="batch-wrap auto">
              <div class="batch-title font16 auto">
                <b>导入名单</b>
              </div>
              <div class="batch-top auto">
                导入说明：请先
                <a-button size="small" @click="downloadHandle">下载模板</a-button>
                ，按照模板格式编辑后上传
              </div>
              <div class="batch-upload auto">
                <a-upload-dragger
                  name="file1"
                  :showUploadList="false"
                  :action="config.api + 'backup/import/external/upload'"
                  @change="handleChange"
                >
                  <p class="ant-upload-drag-icon excel-icon">
                    <a-icon type="file-text" />
                  </p>
                  <p class="ant-upload-text excel-text">
                    将文件拖拽至此区域
                  </p>
                  <p class="ant-upload-hint excel-btn">
                    <a-button size="small">上传文件</a-button>
                  </p>
                </a-upload-dragger>
              </div>
            </div>
          </a-spin>
        </div>
      </div>
    </a-layout-content>
    <a-drawer
      width="100%"
      title=""
      class="access-drawer"
      placement="right"
      :closable="false"
      :visible="visible"
      :get-container="false"
      :wrap-style="{ position: 'absolute' }"
      >
      <a-layout class="content">
        <a-layout-sider class="custom-sider">
          <a-button class="back-btn" size="small" @click="back"><a-icon type="double-left" class="font12" />返回</a-button>
          <div class="batch-menu">
            <ul>
              <li :class="menuIndex === 1 && 'cur'" @click="changeMenu(1)">待导入用户账号</li>
              <li :class="menuIndex === 2 && 'cur'" @click="changeMenu(2)">有问题用户</li>
              <li :class="menuIndex === 3 && 'cur'" @click="changeMenu(3)">有问题账号</li>
            </ul>
          </div>
        </a-layout-sider>
        <a-layout-content class="custom-content auto" style="position:relative;">
          <a-spin :spinning="loading">
            <div class="content-title auto">
              <div class="coustom-title fl" v-if="menuIndex === 1">待导入用户账号（396个）</div>
              <div class="coustom-title fl" v-if="menuIndex === 2">有问题用户（3个）</div>
              <div class="coustom-title fl" v-if="menuIndex === 3">有问题账号（123个）</div>
              <div class="coustom-title-mes fr">
                <a-icon type="info-circle" theme="filled" style="color:#1890FF;margin-right: 10px;" />您一共导入 400 个用户账号，其中：待导入用户账号 346 个，有问题用户 3 个，有问题账号 1 个
              </div>
            </div>
            <div class="content-bar auto">
              <custom-page>
                <div slot="custom">
                  <a-button size="small" class="m-r-5"> 导出当前列表 </a-button>
                  <a-button type="primary" size="small" class="m-r-5" v-show="menuIndex === 1"> 导入 </a-button>
                </div>
              </custom-page>
            </div>
            <div class="content-table auto" v-show="menuIndex === 1 || menuIndex === 2">
              <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
                <div slot="name" slot-scope="text, record">
                  {{text}}
                  <a-popover v-if="record.faceid" class="m-r-5">
                    <template slot="content">
                      <img :src="record.faceid" alt="">
                    </template>
                    <a-icon type="picture" theme="twoTone" two-tone-color="#1890FF" />
                  </a-popover>
                  <a-tooltip v-if="record.modifyComment">
                    <template slot="title">
                      {{record.modifyComment}}
                    </template>
                    <a-icon type="info-circle" theme="twoTone" two-tone-color="#FAAD14" />
                  </a-tooltip>
                </div>
                <div slot="isDisabled" slot-scope="text">
                  <span v-if="text === '0'">
                    <a-badge status="success" :text="text" />
                  </span>
                  <span v-else>
                    <a-badge status="warning" :text="text" />
                  </span>
                </div>
              </a-table>
            </div>
            <div class="content-table auto" v-show="menuIndex === 3">
              <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns2" :data-source="tabledata2" size="middle" :pagination="false">
                <div slot="name" slot-scope="text, record">
                  {{text}}
                  <a-popover v-if="record.faceid" class="m-r-5">
                    <template slot="content">
                      <img :src="record.faceid" alt="">
                    </template>
                    <a-icon type="picture" theme="twoTone" two-tone-color="#1890FF" />
                  </a-popover>
                  <a-tooltip v-if="record.modifyComment">
                    <template slot="title">
                      {{record.modifyComment}}
                    </template>
                    <a-icon type="info-circle" theme="twoTone" two-tone-color="#FAAD14" />
                  </a-tooltip>
                </div>
                <div slot="isDisabled" slot-scope="text">
                  <span v-if="text === '0'">
                    <a-badge status="success" :text="text" />
                  </span>
                  <span v-else>
                    <a-badge status="warning" :text="text" />
                  </span>
                </div>
              </a-table>
            </div>
            <div class="content-bar auto">
              <custom-page>
                <div slot="custom">
                  <a-button size="small" class="m-r-5"> 导出当前列表 </a-button>
                  <a-button type="primary" size="small" class="m-r-5" v-show="menuIndex === 1"> 导入 </a-button>
                </div>
              </custom-page>
            </div>
          </a-spin>
        </a-layout-content>
      </a-layout>
    </a-drawer>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      visible: false,
      menuIndex: 1,
      loading: false,
      tablecolumns: [
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '账号',
          dataIndex: 'type1'
        },
        {
          title: '密码',
          dataIndex: 'type2'
        },
        {
          title: '手机',
          dataIndex: 'type3'
        },
        {
          title: '邮箱',
          dataIndex: 'type4'
        }
      ],
      tabledata: [],
      tablecolumns2: [
        {
          title: '错误信息',
          dataIndex: 'schoolid1'
        },
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '账号',
          dataIndex: 'type1'
        },
        {
          title: '密码',
          dataIndex: 'type2'
        },
        {
          title: '手机',
          dataIndex: 'type3'
        },
        {
          title: '邮箱',
          dataIndex: 'type4'
        }
      ],
      tabledata2: [],
      statusInterval: null
    }
  },
  methods: {
    downloadHandle () {
      window.open(this.config.api + 'backup/import/external/template', '_blank')
    },
    changeMenu (index) {
      this.menuIndex = index
    },
    back () {
      this.visible = false
    },
    showResult () {
      this.visible = true
    },
    handleChange(info) {
      this.loading = true
      const status = info.file.status;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      if (status === 'done') {
        if (!info['file'].response.code) {
          this.statusInterval = setInterval(() => {
            this.externalImportStatus(info['file'].response['data'])
          }, 1000)
        } else {
          // this.$message.error(info['file'].response.description);
          this.$message.error(this.errMsg[info['file'].response['code']])
        }
      } else if (status === 'error') {
        this.loading = false
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    async externalImportStatus (id) {
      let res = await System.externalImportStatus(id)
      if (!res['code']) {
        if (res['data'] === 'success') {
          this.loading = false
          clearInterval(this.statusInterval)
          this.$router.push({
            path: '/system/backup/externallog',
            query: {
              id: id
            }
          })
        }
        if (res['data'] === 'error') {
          this.loading = false
          clearInterval(this.statusInterval)
          this.$message.error('处理失败，请重试！');
        }
      } else {
        this.loading = false
        this.$message.error(this.errMsg[res['code']])
      }
    },
    goLog () {
      this.$router.push('/system/backup/importlog')
    }
  },
  created () {
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.batch-wrap {
  width: 422px;
  margin: 0 auto;
}
.batch-title {
  text-align: center;
  line-height: 62px;
}
.batch-top {
  width: 420px;
  border: 1px solid #91D5FF;
  background: #E6F7FF;
  text-align: center;
  padding: 15px 0;
}
.batch-upload {
  margin-top: 15px;
}
.excel-icon i {
  color: rgba(0,0,0,0.10)!important;
  margin-top: 15px;
}
.excel-text {
  color: rgba(0,0,0,0.25)!important;
  font-size: 14px!important;
  margin-bottom: 10px!important;
}
.excel-btn {
  padding-bottom: 20px;
}

.go-log-btn {
  text-decoration: underline;
  margin-left: 20px;
}

.batch-content2 {
  border-top: 1px solid rgba(0,0,0,0.06);
  margin-top: 30px;
  padding-top: 10px;
}

.batch-upload-box {
  border: 1px dashed rgba(0,0,0,0.15);
  border-radius: 2px;
  text-align: center;
  padding: 30px 0;
}.content-bar {
  padding: 8px 0;
}
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  text-indent: 40px;
}
.batch-menu ul li.cur {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  background: #E6F7FF;
}

</style>
